<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Fundamentals of Explosives Safety" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            This course includes technical training on the 7 basic principles of
            explosive safety with emphasis on explosive operations and risk
            management. Material classification, facility siting, and relevant
            OSHA and ISO standards will be reviewed in order to develop an
            understanding of the elements of a successful risk management plan.
            The course examines the elements of effective process safety
            management, including classifications and relevant testing
            standards. Participants will participate in workshops designed to
            provide a deeper understanding of the application of risk management
            strategies. This course will provide personnel involved with
            explosive manufacturing or processing with the skills necessary to
            successfully integrate the fundamental principles of explosives
            safety into all aspects of energetic processes.
          </p>
          <p>Course Content:</p>
          <ul>
            <li>Material characterization testing</li>
            <li>Sensitivity &amp; reactivity testing</li>
            <li>In-process classification</li>
            <li>Process equipment information</li>
            <li>Process Hazards Analysis (PHA) basics</li>
            <li>Hazards Identification</li>
            <li>Administrative &amp; engineering controls</li>
            <li>Risk reduction and mitigation</li>
            <li>OSHA 29 CFR 1920.119</li>
            <li>Facility siting &amp; design</li>
            <li>Process Safety Information</li>
            <li>Operating procedures</li>
            <li>Pre-startup safety reviews</li>
            <li>Site specific explosives safety standards</li>
            <li>Incident investigations</li>
            <li>Emergency planning and response</li>
            <li>Compliance audits</li>
            <li>PSM &amp; OSHA inspections and enforcement</li>
            <li>ISO 9000 &amp; 1400</li>
            <li>UN/DOT &amp; ATF hazard classification</li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Fundamentals of Explosive Safety",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "This course includes technical training on the 7 basic principles of explosive safety with emphasis on explosive operations and risk management. Material classification, facility siting, and relevant OSHA and ISO standards will be reviewed to develop an understanding of the elements of a successful risk management plan."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
